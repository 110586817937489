#footer {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 20px;
  flex-wrap: wrap;
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  color: white; /* Added flex-wrap property */
  /* border-top: 2px solid black;
  padding-top: 30px; */
}

#columnfirst,
#columnmiddle {
  flex: 1 1 100%; /* Modified flex property */
  margin-right: 20px;
  margin-bottom: 20px; /* Added margin-bottom property */
}

#columnfirst > p > * {
  display: inline-block;
}

#columnfirst > p a {
  text-decoration: none;
  color: white;
}

#columnlast {
  flex: 1 1 100%; /* Modified flex property */
  margin-right: 20px;
  margin-bottom: 20px; /* Added margin-bottom property */
  margin-right: 0;
  margin-bottom: 0; /* Added margin-bottom property */
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

p,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul li {
  margin-bottom: 5px;
}

#footer svg {
  margin-right: 5px;
  font-size: 20px;
  
}
.footer-icon {
margin-bottom: 10px;
}
#footer .footer-icon svg {
  margin-right: 10px;
}
#footer .footer-icon svg {
  font-size: 25px;
 
}
.icons-container>div {
  display: flex;
  align-items: start;
  justify-content: start;
}

@media (min-width: 290px) {
  #columnmiddle,
  #columnlast {
    flex: 0 1 calc(50% - 10px);
  }

  #columnfirst {
    flex: 0 1 calc(50% - 10px);
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  /* .column {
    flex: 0 1 calc(33.33% - 20px); 
     } */

  #footer {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  /* .column {
    flex: 0 1 calc(33.33% - 20px); 
     } */

  #footer * {
    font-size: 12px;
  }
  #footer .footer-icon * {
    font-size: 25px;
  }
}

/* .column p {
  display: flex;
  flex-direction: row;
  gap: 1%;
  margin: 0px;
  padding: 0px;
} */
