/* Define styles for larger screens */
.products-container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 2.25rem;
    gap: 20px;
}
  .slick-slide>div>div{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 95%;
  }
  .slick-slide .all-product-image{
    height: 250px;
  }
  .porduct_head{
    text-align: center;
    position: relative;
    padding-top: 60px;
  }
  .porduct_head>h2,.c-h2{
    font-size: 2.6875rem;
    font-weight: normal;
    font-family: 'Marcellus',serif;
   margin-bottom: 30px;
   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.434);
   background:-webkit-linear-gradient(rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;

  }
  .porduct_head>h6{
    font-size: 13px;
    font-size: 0.8125rem;
   
    color: #5d5b5b;
    font-weight: 400;
    font-family: 'Marcellus',serif;
    text-transform: uppercase;
  }
  .slick-arrow 
  {
    display: none !important;
  }
  /* .bg-gray{
    background-color: #2222;
  } */

  /* Media query for smaller screens (e.g., mobile devices) */
  @media screen and (min-device-width: 650px) and (max-device-width: 768px){
    .products-container {
      grid-template-columns: repeat(3,1fr);
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center; /* Center items on smaller screens */
      padding: 2rem;/* Adjust padding for smaller screens */
      gap: 80px;
    }
  }
  @media screen and (min-device-width: 0px) and (max-device-width: 650px){
    .products-container {
      grid-template-columns: 1fr;
      
    }
  }