.all-product-card {
    background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    width: 310px;
    height: 100%;
    padding: 8px;
  }
  .all-product-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
  }
  .all-product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .all-product-image {
    margin: auto;
    width: 75%;
    height: 200px;
    object-fit: cover;
  }
  
  .all-product-details {
    padding: 16px;
  }
  
  .all-product-name {
    color: white;
    font-size: 15px;
    font-weight:500;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
  .Flex{
    display: flex;
    margin: auto;
  }
  .product-description {
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  .product-price {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
