
.About-Us-content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.25rem;
}
.About-Us-content img{
    border-radius: 10px;
}
.About-Us-content .chakra-tabs__tab-panel {
padding: 0 !important;
}
@media screen and (max-width: 768px) {
    .About-Us-content {
      /* Change the grid layout for smaller screens */
      grid-template-columns: 1fr;
    }
    .About-Us-content.first{
        display: flex;
        flex-direction: column-reverse;
    }
  }