.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  margin: auto;
}
.pro-main {
  /* background-color: #2222; */
  padding-top: 20px;
}
.pagination ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}
#size-btn{
font-weight: 400;
}
.pagination li {
  margin: 0 5px;
}

.pagination a {
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* border: 1px solid black; */
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
}

.pagination a:hover {
  background: linear-gradient(
    to right,
    var(--text-gold) 10%,
    rgb(218, 216, 216) 90%
  );
}

.pagination .active a {
  background: rgb(82 82 82 / 33%);
  color: rgb(12, 10, 10);
}

@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }

  .pagination ul {
    justify-content: center;
  }

  .pagination li {
    margin: 5px;
  }
}
#size-btn:hover {
  background: linear-gradient(
    to right,
    var(--text-gold) 10%,
    rgb(218, 216, 216) 90%
  );
}
#size-btn:focus {
  outline: none;
  border: none;
}
#size-btn option:hover {
  background-color:var(--text-gold); /* Set the desired background color for the option on hover */
}
/* .pagination>.active{
    border: 1px solid red;
    width: 50px;
    height: 50px;
}
.pagination{
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
} */
.chakra-input ,.chakra-textarea{
  border-color: rgba(106, 13, 173, 0.5) !important
  /* Other styles */
}