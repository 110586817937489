.cart_button {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0px 30px;
  top: 50px;
}
.product {
  width: 85%;
  margin: auto;
}
.product_img > img {
  width: 100%;
}
.sale_a {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4em 0.9em;
  font-size: 0.87em;
  font-weight: 500;
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  border-radius: 20px;
}
.cart_a{
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  background: linear-gradient(
    to right,
    rgba(137, 207, 240, 0.5),
    rgba(106, 13, 173, 0.5)
  );
  border-radius: 20px;
}
.cart_a {
  background: linear-gradient(
    to right,
    rgba(137, 207, 240, 0.5),
    rgba(106, 13, 173, 0.5)
  );
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}
.price_cart {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}
/* -----------------------pruductCard1------------- */

.product.container {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background: linear-gradient(
    to right,
    rgba(137, 207, 240, 0.438),
    rgba(106, 13, 173, 0.425)
  );
  border-radius: 12px;
  padding-bottom: 20px;
  filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: -webkit-fill-available;
}

.product.wrapper {
  width: 100%;
  height: 100%;
  box-shadow: none;
}

.banner-image {
  /* background-image: url(https://images.unsplash.com/photo-1641326201918-3cafc641038e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80); */
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 90%;
  border-radius: 12px;
}

.product-name,
.product-cart-price {
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.product.banner-image>img{
  height: 100%;
  width: 100%;
}
.product-details {
  color: #fff;
  font-family: "Marcellus", serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.button-wrapper {
  margin-top: 18px;
  margin: auto;
  display: flex;
  width: 60%;
  justify-content: space-around;
  gap: 1.25rem;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;
  letter-spacing: 2px;
  cursor: pointer;
}

.btn + .btn {
  margin-left: 10px;
}

.outline {
  background: transparent;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.outline:hover {
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.fill {
  background: #b997d7;
  color: rgba(255, 255, 255, 0.95);
  border: 1px solid rgb(255, 255, 255);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all 0.3s ease;
}

.fill:hover {
  transform: scale(1.125);
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
  color: white;
  transition: all 0.3s ease;
}
.modal-overlay {
  background: linear-gradient(to right, rgba(137, 207, 240, 0.5), rgba(106, 13, 173, 0.5));
}
