html {
  scroll-behavior: smooth;
}
.container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar {
  position: relative;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  color: white;
  font-weight: bold;
  /* border: 1px solid white; */
  border-radius: 5px;
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  padding: .625rem;
  backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.logo img {
  width:50px;
}

.menu-icon {
  display: none;
}

/* .nav-elements {
} */

.nav-elements > div {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  height: 55px;
  align-items: center;
  margin-right: 50px;
}
.nav-elements > div > div {
  display: flex;
  align-items: center;
  height: 100%;
}
.nav-elements > div > div:not(:last-child) {
  margin-right: 60px;
}

.nav-elements > div > div > a {
  font-size: 16px;
  /* font-weight: 400; */
  font-weight: bold;
  /* color: black; */
  text-decoration: none;
  color: white;
}

.route > a.active {
  /* color: #574c4c; */
  /* font-weight: 500; */
  position: relative;
  color: white;
}

.route > a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  /* background-color: #574c4c; */
  background-color: white;
}

.route{
  padding: 0px 20px 0px 20px;
}

.route:hover{
  border-radius: 50px 0px 50px 0px;
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
}


@media (max-width: 1024px) {
  .nav-elements > div > div:not(:last-child) {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  
  .menu-icon {
    display: block;
    margin-right: 20px;
    cursor: pointer;
  }
  .nav-elements > div > div:not(:last-child),
  .nav-elements > div > div:last-child {
    margin-right: auto;
    padding-left: 20px;
  }
  .nav-elements > div {
    height: auto;
  }
  
  .nav-elements {
    position: absolute;
    left: 0;
    top: 4.2rem;
    /* background-color: rgb(236, 232, 232); */
    width: 0px;
    height: calc(100vh - 40px);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background: linear-gradient(to right, rgb(0 174 255 / 96%), rgb(106 13 173 / 65%));
    /* border: 1px solid white; */
    border-radius: 10px;
    backdrop-filter: blur(31px) saturate(291%);
  }

  .nav-elements.active {
    /* width: 200px; */
    width: 180px;
  }

  .nav-elements > div {
    display: flex;
    flex-direction: column;
  }

  .nav-elements > div > div {
    margin-right: unset;
    /* margin-top: 22px; */
    margin-top: 15px;
  }
  .logo img {
    width: 40px;
  }
}

@media (min-width: 769px) {
  .container {
    justify-content: space-between;
  }
}
