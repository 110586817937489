.blog_container {
    /* background-image: linear-gradient(to right top, #5f0bfd, #5953ff, #647aff, #7e9bff, #a1b9fc, #a5bcf1, #aabee6, #b1c0db, #92abcf, #7196c3, #4b82b6, #006faa); */
    /* background-image: linear-gradient(180deg, #011b32 20%, #009ffd 100%); */
    background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
    /* border-radius: 0px 0px 20px 20px; */
    /* min-height: 100vh; */
    /* margin-top: -5%; */
    padding-top: 60px;
  }
  
  .blog-slider {
    width: 80%;
    position: relative;
    max-width: 90%;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: auto;
    transition: all 0.3s;
    margin-bottom: 50px;
  }
  .blog-slider * {
    text-align: justify;
  }
  
  @media screen and (max-width: 992px) {
    .blog-slider {
      max-width: 780px;
    }
  }
  .blog_container > h1 {
    margin: 10px 0px 20px 0px;
  }
  @media screen and (max-width: 768px) {
    .blog-slider {
      min-height: 310px;
      height: auto;
      margin: 130px auto 180px auto;
      width: 95%;
    }
    .blog-slider.last-blog-slider {
      margin: 130px auto 30px auto;
    }
    .blog_container > h1 {
      margin: 10px;
    }
  }
  
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider {
      height: 310px;
    }
  }
  
  .blog-slider__item {
    display: flex;
    align-items: center;
    /* justify-items: center; */
    /* text-align: center; */
  }
  
  @media screen and (max-width: 768px) {
    .blog-slider__item {
      flex-direction: column;
    }
  }
  
  .blog-slider__item.swiper-slide-active {
    .blog-slider__img img {
      opacity: 1;
      transition-delay: 0.3s;
    }
    .blog-slider__content > * {
      opacity: 1;
      transform: none;
    }
  }
  
  .blog-slider__img {
    width: 300px;
    flex-shrink: 0;
    height: 300px;
    /* background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%); */
    /* box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2); */
    border-radius: 20px;
    transform: translateX(-80px);
    overflow: hidden;
    box-shadow: rgba(0, 0, 255, 0.8) 0px 0px 10px 0px,
      rgba(255, 255, 255, 0.8) 0px 0px 10px 0px;
  }
  
  .blog-slider__img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0.8;
  }
  
  .blog-slider__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    border-radius: 20px;
    transition: all 0.3s;
    height: 300px;
    margin: auto;
  }
  
  @media screen and (max-width: 992px) {
    .blog-slider__img {
      width: 45%;
      background-color: white;
    }
  }
  
  @media screen and (max-width: 768px) {
    .blog-slider__img {
      transform: translateY(-50%);
      width: 90%;
      background-color: white;
    }
  }
  @media screen and (min-width: 768px) {
    .blog-slider__img.right {
      transform: translateX(80px);
    }
  }
  
  @media screen and (max-width: 576px) {
    .blog-slider__img {
      width: 95%;
    }
  }
  
  @media screen and (max-height: 500px) and (min-width: 992px) {
    .blog-slider__img {
      height: 270px;
    }
  }
  
  .blog-slider__title {
    text-align: center;
  }
  
  @media screen and (max-width: 992px) {
    .blog-slider__content {
      width: 55%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .blog-slider__content {
      margin-top: -80px;
      text-align: center;
      padding: 0 10px;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 576px) {
    .blog-slider__content {
      padding: 0;
      width: 100%;
    }
  }
  
  .blog-slider__content > * {
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.4s;
  }
  
  .blog-slider__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
  }
  
  .blog-slider__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
  }
  
  .blog-slider__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  
  .blog-slider__button {
    display: inline-flex;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
  }
  
  @media screen and (max-width: 576px) {
    .blog-slider__button {
      width: 100%;
    }
  }
  
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  
  .blog-slider__pagination {
    position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%);
  }
  
  @media screen and (max-width: 768px) {
    .blog-slider__pagination {
      transform: translateX(-50%);
      left: 50% !important;
      top: 205px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 0;
  }
  
  @media screen and (max-width: 768px) {
    .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }
  
  .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 10px;
    background: #062744;
    opacity: 0.2;
    transition: all 0.3s;
  }
  
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
    background: #fd3838;
    height: 30px;
    box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
  }
  
  @media screen and (max-width: 768px) {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      height: 11px;
      width: 30px;
    }
  }
  