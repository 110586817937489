/* Style for ProductDetails component */

/* Container styling */
.product-container {
  margin: 0 auto;
  padding: 1rem;
  font-family: "Marcellus", serif;
  display: flex;
  flex-wrap: wrap;
}
.section-feature{
  margin: 0 auto;
  padding: 20px;
}
/* Product image */
.product-image {
  flex: 1;
  max-width: 50%;
  height: auto;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.btn-container{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2%;
}

.btn-container>a> button{
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  padding: 10px 20px;
  color: white;
}
.btn-container>a> button:hover{
  transition: all .5s;  
border-radius: 50px 0px 50px 0px;  
}


/* Details container */
.details-container {
  flex: 1;
  max-width: 50%;
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  padding: 3%;
  color: white;
  border-radius: 15px;

}

/* Product name */
.Product-name {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Price */
.Product-price {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Section headers */
.section-header {
  margin: 0;
  font-size: 20px;
  margin-bottom: 10px;
}

/* Lists */
.product-list {
  list-style: none;
  padding: 0;
}

/* .product-list-item {
  margin-bottom: 8px;
} */

/* Additional Information */
.additional-info {
  /* margin-top: 20px; */
  font-style: italic;
  color: #888;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .product-image,
  .details-container {
    max-width: 100%;
    margin-right: 0;
  }
  .product-image{
    border: 1px solid #ccc;
    border-bottom: none; /* Remove bottom border */
    border-radius: 10px 10px 0 0;
  }
  .details-container{
    border-radius: 0 0 10px 10px;
  }
}
.css-52dxnr[aria-selected=true], .css-52dxnr[data-selected] {
  background: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  border-radius: 50px 0px 50px 0px;
  border: 0px;
  color: white;
}