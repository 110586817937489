.cer_imag{
    border:5px solid;
    border-image: linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5));
  border-image-slice: 1;

}
.EnlargedImage__overlay{
    background:linear-gradient(to right, rgba(137, 207, 240, 0.5), rgba(106, 13, 173, 0.5))
}
.EnlargedImage__clone{
    padding: 20px 0px;
}