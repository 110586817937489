.container_dc {
  position: relative;
}

.image_dc {
  width: 100vw;
  /* height: 60vh; */
  /* Adjust the image size as needed */
}
.image_dc::after{
  content: " ";
  width: 100vw;
  height: 60vh;
  position: absolute;
  top: 0;
  background-color: rgb(0, 0, 0);
}
.text_dc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7); /* Background color for text */
  /* padding: 10px; */
  /* font-size: 18px; */
  font-weight: bold;
  /* width: 50%;
  max-width: 200px; */
}

.text_dc > button {
  background: linear-gradient(
    to right,
    rgba(0, 174, 255, 0.5),
    rgba(106, 13, 173, 0.5)
  );
  color: white;
  /* padding: 5%; */
  font-size: 2rem;
  /* width: 100%; */
}
@media screen and (max-width: 768px){
  .image_dc {
    /* height: 50vw; */
    /* Adjust the image size as needed */
  }
}