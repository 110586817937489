@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Kanit");
body {
  background-color: white;
  margin: 0px !important;
  /* background-color: rgba(106, 13, 173, 0.5); */
}
* {
  scroll-behavior: smooth;
}
section {
  background: linear-gradient(
    to right,
    rgba(0, 174, 255, 0.5),
    rgba(106, 13, 173, 0.5)
  );
}
.custency {
  width: 90%;
  margin: auto;
}
section {
  padding-top: 60px;
  padding-bottom: 60px;
}
