.home_banner {
  height: 100vh;
  width: 100%;
  /* margin-top: -90px; */
  overflow: hidden; /* Ensure video doesn't overflow the container */
  
}



.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire div */
  background-image: url("./1.jpg");
  background-position: 0px 0px;
  background-repeat: repeat-x;
  background-attachment: fixed;
  background-size: cover;
  transition: ease-out 0.5s;
}

.banner_text {
  position: absolute;
  top: 80px;
  left:0;
  width: 100%;
  height: inherit;
  background: linear-gradient(to right, rgba(137, 207, 240, 0.5), rgba(106, 13, 173, 0.5));
  
}

.banner_text > h1 {
  font-size: 3.9375rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  color: white;
  margin-left: 40px;
  margin-top: 15%;
  margin-right: 40px;
}
.banner_text p{
  color: white;
  margin-left: 40px;
}
.banner_text > h4 {
  font-size: 1.4375rem;
  line-height: 1.2em;
  font-weight: 400;
  font-family: "Marcellus", serif;
  color: white;
  margin-top: -20px;
  margin-left: 40px;
  margin-right: 40px;
}
.banner_text > a {
  font-weight: 500;
  line-height: 1em;
  background-color: white;
  padding: 12px 30px;
  margin-left: 40px;
}
@media (max-width: 768px) {
  .banner_text {
    top:70px;
    position: absolute;
    padding:5%;
    /* width: 90%; */
    height: inherit;
  }
  
  .home_banner{
    height: 50vh;

  }
  /* .banner_text {
    top: 15%;
  } */
  .banner_text > h1 {
    font-size: 2.3rem;
    margin-top: 0;
  }
  
  .banner_text > h4 {
    font-size: 1.2rem;
    line-height: 1.2em;
  }
}
